import React, { useEffect, useState } from "react";
import msgGif from '../assets/img/chat-anim.gif';
import userImg from '../assets/img/demo-img.jpg';
import { FaTimes } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { FaArrowRotateRight } from "react-icons/fa6";
import { FaPaperPlane } from "react-icons/fa6";
import { FaStar } from "react-icons/fa";
import rexettIcon from '../assets/img/icon-logo.png';
const ChatSupport = ({openChat, SetOpenChat}) => {
    const [isChatVisible, setChatVisible] = useState(false);
    const [isFeedbackVisible, setFeedbackVisible] = useState(false);
    const [isMinimized, setMinimized] = useState(false);
    useEffect(() =>{
        if(openChat === true){
            showChat()
        }
    },[openChat])

    const showChat = () => {
        setChatVisible(true);
        setMinimized(false);
        SetOpenChat(!openChat)
    };

    const closeChat = () => {
        setFeedbackVisible(true);
        setMinimized(true);
    };

    const minimizeChat = () => {
        setChatVisible(false);
        setMinimized(true);
        setFeedbackVisible(false);
    };

    const showFeedback = () => {
        setFeedbackVisible(true);
    };

    const closeFeedback = () => {
        setFeedbackVisible(false);
    };

    return (
        <>
            <button className="mobile-chat-btn" onClick={showChat}>
                <img src={msgGif} alt="Chat" />
            </button>
            {isChatVisible && (
                <div className="chat-support">
                    <div className="chat-header">
                        <h3>Rexett Support</h3>
                        <div className="d-flex gap-1 align-items-center">
                            {isMinimized && (
                                <button
                                    className="feedback-btn chatheader-btn"
                                    onClick={showFeedback}
                                >
                                    <FaStar />
                                </button>
                            )}
                            <button
                                className="refresh-btn chatheader-btn"
                            >
                                <FaArrowRotateRight />
                            </button>
                            <button
                                className={isMinimized ? "close-chat-btn chatheader-btn d-none" : "close-chat-btn chatheader-btn"}
                                onClick={closeChat}
                            >
                                <FaTimes />
                            </button>
                            {isMinimized && (
                                <button
                                    className="minimize-chat-btn chatheader-btn"
                                    onClick={minimizeChat}
                                >
                                    <FaMinus />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="chat-screen position-relative">
                        {isFeedbackVisible && (
                            <div className="feedback-screen">
                                <div>
                                    <div className="feedback">
                                        <button className="close-feedback" onClick={closeFeedback}>
                                            <FaTimes />
                                        </button>
                                        <h3 className="mb-3">
                                            How would you rate your support experience today?
                                        </h3>
                                        <div className="rating">
                                            <input type="radio" name="rating" id="rating-5" />
                                            <label htmlFor="rating-5"></label>
                                            <input type="radio" name="rating" id="rating-4" />
                                            <label htmlFor="rating-4"></label>
                                            <input type="radio" name="rating" id="rating-3" />
                                            <label htmlFor="rating-3"></label>
                                            <input type="radio" name="rating" id="rating-2" />
                                            <label htmlFor="rating-2"></label>
                                            <input type="radio" name="rating" id="rating-1" />
                                            <label htmlFor="rating-1"></label>
                                        </div>
                                        <button className="common-btn font-14 mt-4">Submit</button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="message-container">
                            <div className="inner-message-container">
                                <div className="rexett-message">
                                    <div className="flex-none">
                                        <img src={rexettIcon} className="rexett-icon" alt="Logo" />
                                    </div>
                                    <p className="upc-message">
                                        Hi John Doe Welcome to Rexett Support! 👋 I'm here to help you
                                        find quick solutions and detailed guidance. To assist you
                                        better, please provide a detailed description of your issue
                                        or question.
                                    </p>
                                </div>
                                <div className="my-messagebx">
                                    <p className="my-message">
                                        Hi, I am getting issue from time reporting
                                    </p>
                                    <div className="my-imgbx">
                                        <img src={userImg} alt="User" />
                                    </div>
                                </div>
                                <div className="rexett-message">
                                    <div className="flex-none">
                                        <img src={rexettIcon} className="rexett-icon" alt="Logo" />
                                    </div>
                                    <div className="typing-dots -alt">
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex message-field-wrapper align-items-center px-2 gap-2">
                            <input type="text" className="msg-field" placeholder="Type here" />
                            <button
                                className="send-btn"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Send Message"
                            >
                                <FaPaperPlane />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ChatSupport;
