import React from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import '../../assets/css/style.css';
import { useNavigate } from "react-router-dom";

const RaiseTicket = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate(); 
    
    const token = localStorage.getItem('access_token') || process.env.REACT_APP_DEFAULT_TOKEN;

    const onSubmit = async (data) => {
        try {
            // Construct the payload
            const payload = {
                subject: data.topic,
                short_description: data.shortDescription,
                description: data.detailedDescription,
                url: data.url || '',
                attachments: [] // Attachments should be handled separately
            };

            // Make the API call
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tickets/`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'token': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            // Check if the response is okay
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log(result); // Handle the result as needed
            
            // Show success message and redirect
            // alert('Ticket submitted successfully!');
            navigate('/ticket-list'); // Redirect to the ticket list page
        } catch (error) {
            console.error('Error:', error);
            // Optionally show an error message to the user
        }
    };

    return (
        <>
            <section className="section-top-space article-wrapper">
                <Container>
                    <div className="inner-article-wrapper">
                        <Row>
                            <Col md={9}>
                                <Breadcrumb className="custom-breadcrumb">
                                    <Breadcrumb.Item href="/">Rexett | Help Center</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Raise Ticket</Breadcrumb.Item>
                                </Breadcrumb>
                                <div className="mb-4">
                                    <h3 className="single-article-heading">Submit a request</h3>
                                    <p className="time-posted">You can contact one of our friendly Customer Success team members below. Begin by selecting a topic we can help you with.</p>
                                </div>
                                <div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="mb-20">
                                            <label className="form-label request-label">Hi, what can we help you with?</label>
                                            <select
                                                className="form-control form-select request-field shadow-none"
                                                {...register("topic", { required: "This field is required" })}
                                            >
                                                <option value="">Select a topic</option>
                                                <option value="registration">Registration</option>
                                                <option value="profile-updation">Profile Updation</option>
                                                <option value="time-reporting">Time Reporting</option>
                                                <option value="raise-invoice">Raise Invoice</option>
                                                <option value="payments">Payments</option>
                                                <option value="site-feedbacks">Site Feedback</option>
                                                <option value="job-posting">Job Posting</option>
                                                <option value="document-uploading">Document Uploading</option>
                                                <option value="others">Others</option>
                                            </select>
                                            {errors.topic && <p className="error-text">{errors.topic.message}</p>}
                                        </div>
                                        <div className="mb-20">
                                            <label className="form-label request-label">In a few words, tell us what your enquiry is about</label>
                                            <input
                                                type="text"
                                                className="form-control request-field shadow-none"
                                                {...register("shortDescription", { required: "This field is required" })}
                                            />
                                            {errors.shortDescription && <p className="error-text">{errors.shortDescription.message}</p>}
                                        </div>
                                        <div className="mb-20">
                                            <label className="form-label request-label">Provide a detailed description</label>
                                            <textarea
                                                className="form-control request-field shadow-none"
                                                rows="3"
                                                {...register("detailedDescription", { required: "This field is required" })}
                                            ></textarea>
                                            {errors.detailedDescription && <p className="error-text">{errors.detailedDescription.message}</p>}
                                            <span className="helper-text">Please enter the details of your request. Remember: Never share passwords and don’t provide personal, sensitive or confidential information to anyone you don’t know or who doesn’t have a legitimate need for it.</span>
                                        </div>
                                        <div className="mb-20">
                                            <label className="form-label request-label">Include a relevant URL (optional)</label>
                                            <input
                                                type="text"
                                                className="form-control request-field shadow-none"
                                                {...register("url")}
                                            />
                                            <span className="helper-text">Linking us directly to an item or page that relates to your request will speed up our response. Please include 'http://' or 'https://'.</span>
                                        </div>
                                        <div className="mb-20">
                                            <label className="form-label request-label">Attachments (optional)</label>
                                            <input
                                                type="file"
                                                id="attached-file"
                                                className="form-control"
                                                accept=".jpeg, .jpg, .png, .mp4"
                                                {...register("attachment")}
                                            />
                                            <label htmlFor="attached-file" className="attached-label">Add attachment file</label>
                                            <span className="helper-text">Please attach only jpeg, jpg, png, mp4 files.</span>
                                            <div className="uploaded-attached">
                                                <div>
                                                    <i className="fa-solid fa-paperclip"></i>
                                                    <span className="attached-filename">invoice-paid.png</span>
                                                </div>
                                                <button className="remove-attach">&times;</button>
                                            </div>
                                        </div>
                                        <button type="submit" className="submit-btn mt-5">Submit Request</button>
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}
export default RaiseTicket;
