import React from "react";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { Col, Row, Nav, Tab, Container } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa6";
import StillNoLuck from "../../components/StillNoLuck";
const Home = () => {
    return (
        <>
            <section className="banner-section">
                <div className="container">
                    <div className="inner-banner">
                        <Row>
                            <Col lg={7}>
                                <h1 className="help-heading">Hi, how can we <span className="help-highlight">help?</span></h1>
                                <div className="d-flex align-items-center field-wrapper">
                                    <input type="text" className="form-control question-field shadow-none border-0"
                                        placeholder="Ask us a question..." />
                                    <button className="search-btn"><FaSearch /></button>
                                </div>
                                <p className="common-topic-text mb-0">Common Topics : <a href="#"
                                    className="common-topic-pill">Invoice</a><a href="#"
                                        className="common-topic-pill">Payments</a><a href="#" className="common-topic-pill">Time
                                            Reporting</a><a href="#" className="common-topic-pill">Job Posting</a><a href="#"
                                                className="common-topic-pill">Profile Updation</a></p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
            <section className="article-section">
                <div className="container">
                    <div className="inner-article">
                        <h2 className="article-heading">Choose an account type for <span className="highlight-text">personalized
                            service</span></h2>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="developer-link">
                            <Nav variant="pills" id="pills-tab" className="account-pills mb-5">
                                <Nav.Item className="account-item">
                                    <Nav.Link className="account-link" eventKey="developer-link">Developers</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="account-item">
                                    <Nav.Link className="account-link" eventKey="clients-link">Clients</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="account-item">
                                    <Nav.Link className="account-link" eventKey="vendors-link">Vendors</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="developer-link">
                                    <Row>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Join Rexett</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>Join Rexett as a Developer<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Eligibility to Join and Use Rexett<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Complete onboarding Projects<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Sharing Information on Rexett<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Create a 100% Complete Developer Profile<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Time Reporting</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>Manage Time Reporting<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Timesheet Bulk Edit<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Checkin/Checkout<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Apply/Cancel Leave<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>View Timesheet Records<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Fees & Protection</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>Raising Invoice<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Developer Payment Protection<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Build Your Profile</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>Build Your Profile - Essential Items<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Enhance your profile<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Add Skills<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Add Certifications<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Get Paid</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>Manage How You Get Paid<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Why Can't I Get Paid Directly?<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Confirmation of Services<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Reports & Earnings</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>View Invoices<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Certificate of Earnings<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Earning Reports<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="clients-link">
                                    <Row>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Join Rexett</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>Join Rexett as a Developer<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Eligibility to Join and Use Rexett<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Complete onboarding Projects<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Sharing Information on Rexett<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Create a 100% Complete Developer Profile<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Fees & Protection</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>Raising Invoice<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Developer Payment Protection<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Build Your Profile</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>Build Your Profile - Essential Items<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Enhance your profile<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Add Skills<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Add Certifications<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Get Paid</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>Manage How You Get Paid<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Why Can't I Get Paid Directly?<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Confirmation of Services<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Reports & Earnings</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>View Invoices<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Certificate of Earnings<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Earning Reports<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="vendors-link">
                                    <Row>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Join Rexett</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>Join Rexett as a Developer<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Eligibility to Join and Use Rexett<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Complete onboarding Projects<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Sharing Information on Rexett<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Create a 100% Complete Developer Profile<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Fees & Protection</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>Raising Invoice<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Developer Payment Protection<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Build Your Profile</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>Build Your Profile - Essential Items<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Enhance your profile<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Add Skills<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Add Certifications<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Get Paid</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>Manage How You Get Paid<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Why Can't I Get Paid Directly?<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Confirmation of Services<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <h3 className="heading-list">Reports & Earnings</h3>
                                            <ul className="article-listing">
                                                <li>
                                                    <Link to={'/single-article'}>View Invoices<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Certificate of Earnings<FaChevronRight /></Link>
                                                </li>
                                                <li>
                                                    <Link to={'/single-article'}>Earning Reports<FaChevronRight /></Link>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </section>
            <Container>
                <StillNoLuck />
            </Container>
        </>
    )
}
export default Home;