import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";

const TicketList = () => {
    const [tickets, setTickets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 10;

    const [statusFilter, setStatusFilter] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);

    const token = localStorage.getItem('access_token') || process.env.REACT_APP_DEFAULT_TOKEN;

    const fetchTickets = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tickets/?page=${currentPage}&limit=${limit}&status=${statusFilter}&search=${searchTerm}`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'token': token
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setTickets(data.tickets);
            setTotalRecords(data.total_count);
            setTotalPages(Math.ceil(data.total_count / limit));

        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTickets();
    }, [currentPage, statusFilter, searchTerm]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleStatusChange = (event) => {
        setStatusFilter(event.target.value);
        setCurrentPage(1); // Reset to first page when changing filter
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        setCurrentPage(1); // Reset to first page when submitting search
        fetchTickets();
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    // Calculate the start and end index for the current page
    const startRecord = (currentPage - 1) * limit + 1;
    const endRecord = Math.min(currentPage * limit, totalRecords);

    return (
        <>
            <section className="banner-section">
                <Container>
                    <div className="inner-banner">
                        <Row>
                            <Col md={7}>
                                <h1 className="help-heading">Raised Tickets</h1>
                                <form onSubmit={handleSearchSubmit} className="d-flex align-items-center field-wrapper">
                                    <input
                                        type="text"
                                        className="form-control question-field shadow-none border-0"
                                        placeholder="Search Raised Tickets"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    <button type="submit" className="search-btn"><FaSearch /></button>
                                </form>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section className="raised-wrapper">
                <Container>
                    <div className="inner-raised-wrapper">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h3 className="single-article-heading mb-0">Total Tickets ({totalRecords})</h3>
                            <select
                                className="form-select form-control w-auto shadow-none ticket-filter request-field"
                                value={statusFilter}
                                onChange={handleStatusChange}
                            >
                                <option value="">Any</option>
                                <option value="open">Open</option>
                                <option value="progress">In Progress</option>
                                <option value="closed">Closed</option>
                            </select>
                        </div>
                        {tickets.map(ticket => (
                            <Link to={`/ticket-detail?id=${ticket.id}`} key={ticket.id} className="ticket-wrapper d-block mb-3">
                                <Row>
                                    <Col md={9}>
                                        <p className="text-eyebrow">#{ticket.id}</p>
                                        <h3 className="ticket-heading">{ticket.short_description}</h3>
                                        <span className="category-text">{ticket.subject.replace('-', ' ').toUpperCase()}</span>
                                    </Col>
                                    <Col md={3}>
                                        <p className="date-ticket">{new Date(ticket.created_at).toLocaleDateString()}</p>
                                        <div className="d-md-block d-inline-block">
                                            <span className={`ticket-status status-${ticket.status}`}>
                                                {ticket.status.charAt(0).toUpperCase() + ticket.status.slice(1)}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <p className="issue-desc">{ticket.description}</p>
                                    </Col>
                                </Row>
                            </Link>
                        ))}
                    </div>
                </Container>
            </section>
            <Container>
                <div className="d-flex justify-content-between align-items-center">
                    <p className="pagination-result">
                        Showing {tickets.length} of {totalRecords}
                    </p>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination custom-pagination justify-content-end">
                            <li className="page-item">
                                <a
                                    className="page-link"
                                    href="#"
                                    aria-label="Previous"
                                    onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                                >
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>
                            {[...Array(totalPages)].map((_, index) => (
                                <li className="page-item" key={index + 1}>
                                    <a
                                        className={`page-link ${currentPage === index + 1 ? 'active' : ''}`}
                                        href="#"
                                        onClick={() => handlePageChange(index + 1)}
                                    >
                                        {index + 1}
                                    </a>
                                </li>
                            ))}
                            <li className="page-item">
                                <a
                                    className="page-link"
                                    href="#"
                                    aria-label="Next"
                                    onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                                >
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </Container>
        </>
    );
};

export default TicketList;
