import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import userImg from '../../assets/img/demo-img.jpg';
import rexettIcon from '../../assets/img/icon-logo.png';
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { useParams, useLocation } from 'react-router-dom';

const TicketDetail = () => {
    const [ticket, setTicket] = useState(null);
    const [comments, setComments] = useState([]); // State for comments
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [comment, setComment] = useState(""); // State for CKEditor content
    const [commentError, setCommentError] = useState(""); // State for comment errors

    const { id } = useParams(); // Get ticket ID from URL params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const ticketId = queryParams.get('id'); // Get ticket ID from query params
    const token = localStorage.getItem('access_token') || process.env.REACT_APP_DEFAULT_TOKEN;

    const fetchTicketAndComments = async () => {
        try {
            // Fetch ticket details
            const ticketResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tickets/${ticketId}`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'token': token
                }
            });

            if (!ticketResponse.ok) {
                throw new Error('Failed to fetch ticket details');
            }

            const ticketData = await ticketResponse.json();
            setTicket(ticketData);

            // Fetch comments
            const commentsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tickets/${ticketId}/comments`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json',
                    'token': token
                }
            });

            if (!commentsResponse.ok) {
                throw new Error('Failed to fetch comments');
            }

            const commentsData = await commentsResponse.json();
            setComments(commentsData.comments);

        } catch (error) {
            setError(error.message);
        } 
    };

    useEffect(() => {
        setLoading(true);
        if (ticketId) {
            fetchTicketAndComments();
        }
        setLoading(false);
    }, [ticketId]);

    const handleCommentSubmit = async () => {
        if (!comment.trim()) {
            setCommentError("Comment cannot be empty.");
            return;
        }
        console.log('post comment');
        
        setCommentError(""); // Clear previous errors

        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/tickets/${ticketId}/comment?comment=${encodeURIComponent(comment)}`, {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'token': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ comment })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log('Comment posted:', result);

            // Optionally clear the comment field or update the UI to reflect the new comment
            setComment("");
            fetchTicketAndComments();
        } catch (error) {
            setCommentError(error.message);
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    if (!ticket) {
        return <p>No ticket found</p>;
    }

    return (
        <>
            <section className="section-top-space article-wrapper">
                <Container>
                    <div className="inner-article-wrapper">
                        <Row>
                            <Col md={12}>
                                <Breadcrumb className="custom-breadcrumb">
                                    <Breadcrumb.Item href="/">Rexett | Help Center</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/ticket-list">Tickets List</Breadcrumb.Item>
                                    <Breadcrumb.Item active>{ticket.short_description}</Breadcrumb.Item>
                                </Breadcrumb>
                                <div className="mb-4">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <p className="text-eyebrow fw-medium">#{ticket.id}</p>
                                        <div className="d-flex align-items-center gap-2">
                                            <p className="time-posted mb-0">{new Date(ticket.created_at).toLocaleDateString()}</p>
                                            <span className={`ticket-status status-${ticket.status}`}>{ticket.status.charAt(0).toUpperCase() + ticket.status.slice(1)}</span>
                                        </div>
                                    </div>
                                    <h3 className="single-article-heading d-flex align-items-center gap-2 mb-1">{ticket.short_description}</h3>
                                    <span className="category-text mb-0">{ticket.subject.replace('-', ' ').toUpperCase()}</span>
                                </div>
                                <div className="content-wrapper">
                                    <p>{ticket.description}</p>
                                </div>
                                <div className="attachments-added">
                                    <h3 className="section-heading">Attachments(0)</h3>
                                    <p className="text-error">No attachments</p>
                                </div>
                                <div className="helpful-article">
                                    <p className="helpful-heading">Response ({comments.length})</p>
                                    <div className="comment-wrapper">
                                        {comments.map((comment) => (
                                            <div className="comment-container" key={comment.id}>
                                                <div className="comment-info">
                                                    <div className="d-flex align-items-center gap-2">
                                                        <div className="comment-pic">
                                                            <img src={rexettIcon} alt="User" />
                                                        </div>
                                                        <h3 className="comment-user">{comment.sender_name} ({comment.sender_type})</h3>
                                                    </div>
                                                    <div>
                                                        <p className="comment-date">{new Date(comment.created_at).toLocaleDateString()}</p>
                                                    </div>
                                                </div>
                                                <div className="comment-written">
                                                    <div dangerouslySetInnerHTML={{ __html: comment.comment }} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="add-comment-wrapper">
                                        <p className="helpful-heading">Add Reply</p>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            id="editor"
                                            data={comment}
                                            config={{
                                                toolbar: [
                                                    'bold', 'italic', 'underline', 'strikethrough', '|',
                                                    'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|',
                                                    'bulletedList', 'numberedList', '|',
                                                    'heading', 'removeFormat', 'code'
                                                ],
                                                fontSize: {
                                                    options: [
                                                        'tiny',
                                                        'small',
                                                        'default',
                                                        'big',
                                                        'huge'
                                                    ]
                                                },
                                                fontFamily: {
                                                    options: [
                                                        'default',
                                                        'Arial, Helvetica, sans-serif',
                                                        'Courier New, Courier, monospace',
                                                        'Georgia, serif',
                                                        'Lucida Sans Unicode, Lucida Grande, sans-serif',
                                                        'Tahoma, Geneva, sans-serif',
                                                        'Times New Roman, Times, serif',
                                                        'Trebuchet MS, Helvetica, sans-serif',
                                                        'Verdana, Geneva, sans-serif'
                                                    ]
                                                }
                                            }}
                                            onChange={(event, editor) => {
                                                setComment(editor.getData());
                                                console.log({ event, editor, data: editor.getData() });
                                            }}
                                        />
                                        {commentError && <p className="text-error">{commentError}</p>}
                                        <div className="text-end mt-3">
                                            <button className="common-btn font-14" onClick={handleCommentSubmit}>Post a comment</button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default TicketDetail;
