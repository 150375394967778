import React from "react";
import { Navbar, Nav, NavDropdown, Container, Button, Image } from 'react-bootstrap';
import demoImg from '../assets/img/demo-img.jpg';
import logoImg from '../assets/img/rexett-logo.png';
import { Link } from "react-router-dom";
const Navigation = ({SetOpenChat, openChat}) => {
    return (
        <>
            <Navbar expand="lg" className="custom-header py-3">
                <Container>
                    <Navbar.Brand as={Link} to="/" className="header-logo">
                        <img src={logoImg} /> | Help Center
                    </Navbar.Brand>
                    <div className="d-flex gap-3 align-items-center">
                        <Navbar.Toggle aria-controls="headerToggle" className="shadow-none" />
                        <div className="d-lg-none ms-0 login-userprofile">
                            <NavDropdown
                                title={<img src={demoImg} className="user-avatar" />}
                                id="dropdownMenuButton1"
                                align="end"
                                className="ms-0"
                            >
                                <NavDropdown.Item href="#">Logout</NavDropdown.Item>
                            </NavDropdown>
                        </div>
                    </div>
                    <Navbar.Collapse id="headerToggle" className="justify-content-between pt-lg-0 pt-4">
                        <Nav className="mx-auto mb-2 mb-lg-0">
                            <Nav.Link href="/raise-ticket">Raise Ticket</Nav.Link>
                            <Nav.Link href="/ticket-list">Tickets Listing</Nav.Link>
                            <Nav.Link href="#" onClick={() => SetOpenChat(!openChat)} className="chat-support-link">Chat with support</Nav.Link>
                        </Nav>
                        <Nav className="d-lg-block d-none">
                            <NavDropdown
                                title={
                                    <div className="d-flex align-items-center login-userprofile">
                                        <img src={demoImg} roundedCircle className="user-avatar" />
                                        <div className="d-none d-lg-block ms-2">
                                            <p className="user-name mb-0">John Doe</p>
                                            <p className="user-email mb-0">johndoe@gmail.com</p>
                                        </div>
                                    </div>
                                }
                                id="navDropdownWrapper"
                                align="end"
                                className="ms-0 custom-dropdown"
                            >
                                <div>
                                    <NavDropdown.Item href="#">Logout</NavDropdown.Item>
                                </div>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}
export default Navigation;