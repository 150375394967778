import React, { useState } from "react";
import { Container, Row, Col, Breadcrumb, Button } from "react-bootstrap";
import articleImg from '../../assets/img/rexett_home.png'
import StillNoLuck from "../../components/StillNoLuck";
import { Link } from "react-router-dom";
const SingleArticle = () => {
    const [helpfulyes, setHelpfulYes] = useState(false);
    const showResponse = () => {
        setHelpfulYes(true);
    }
    const hideResponse = () => {
        setHelpfulYes(false);
    }
    return (
        <>
            <section className="section-top-space article-wrapper">
                <Container>
                    <div className="inner-article-wrapper">
                        <Row>
                            <Col lg={9}>
                                <Breadcrumb className="custom-breadcrumb">
                                    <Breadcrumb.Item href="/">Rexett | Help Center</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Raise Ticket</Breadcrumb.Item>
                                </Breadcrumb>
                                <div className="mb-4">
                                    <h3 className="single-article-heading">Join Rexett as a Developer</h3>
                                    <p className="time-posted">1 month ago</p>
                                </div>
                                <div className="content-wrapper">
                                    <p>Rexett is one of the world's first subscription services to offer unlimited downloads of millions of high-quality graphic design templates & assets via an affordable subscription fee.</p>
                                    <p>Subscribers get unlimited access to the full library of content on Rexett. There are no credit packs or download limits to worry about. Simply subscribe to our service and gain access to a wide range of items that you can use for your projects.</p>
                                    <img src={articleImg} />
                                    <h2 id="subscription-access" className="inner-single-heading">Subscription access</h2>
                                    <p>To access Rexett, you must have an active subscription. Your subscription entitles you to unlimited downloads and is charged monthly and in advance, starting from the date you subscribe.</p>
                                    <p>Your subscription will automatically renew each month on the anniversary of the date you first subscribed. You can cancel at any time, however, you must have an active subscription to use any items you've downloaded.</p>
                                    <p>Rexett accepts Visa, Mastercard, American Express (USD only), Apple Pay and PayPal as payment methods. You can also use American Express with your PayPal account for payment.</p>
                                    <p>It's important to note that not all currency is accepted by PayPal. You can visit PayPal for more information or contact PayPal directly if you're having trouble making a payment with your currency. </p>
                                    <p>As per our user terms, please remember:</p>
                                    <ol>
                                        <li>The subscription is for an individual or Teams (for the Enterprise plan check here)</li>
                                        <li>No robots are allowed. So that means no sharing of accounts or use of automated tools to scrape & download items</li>
                                    </ol>
                                    <p>Visit our pricing page to learn more and subscribe to Rexett.</p>
                                    <h2 id="subscription-assets" className="inner-single-heading">Subscription assets</h2>
                                    <p>Items on Rexett are created by independent designers who have been hand-selected by our team for their ability to create exceptional content. Each designer brings their style, perspective, and expertise to the items they create. This diversity ensures that a wide range of content is available on Rexett, catering to different design needs and preferences. Check the Rexett library here.</p>
                                    <p>Once you find the item you need, simply click to download it and obtain the necessary license. It's as easy as that! Whether you're looking for graphics, music, videos, or any other type of creative asset, our platform has you covered. All items are licensed under a single, commercial license enabling use in most projects. </p>
                                </div>
                                <div className="helpful-article">
                                    <p className="text-center helpful-heading">Was this article helpful?</p>
                                    <div className={helpfulyes ? "d-none justify-content-center gap-3" : "d-flex justify-content-center gap-3"}>
                                        <Button variant="transparent" className="btn-yes helpful-btn" onClick={showResponse}>Yes</Button>
                                        <Link to={'/raise-ticket'} variant="transparent" className="btn-no helpful-btn">No</Link>
                                    </div>
                                    {helpfulyes && (
                                        <p className="thanks-text text-center" onClick={hideResponse}>Thank you for your response. <Button variant="transparent" className="text-btn p-0 ms-2">Undo</Button></p>
                                    )}
                                    <p className="text-center helpful-rate">138 out of 1271 found this helpful</p>
                                </div>
                                <StillNoLuck />
                            </Col>
                            <Col lg={3} className="d-lg-block d-none">
                                <div className="table-content">
                                    <h3 className="table-content-heading">In this article</h3>
                                    <div className="table-content-listing">
                                        <a href="#subscription-access" className="table-content-link active">Subscription access</a>
                                        <a href="#subscription-assets" className="table-content-link">Subscription assets</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}
export default SingleArticle;