import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
const StillNoLuck = () =>{
    return(
        <>
            <div className="still-wrapper">
                <h3 className="table-content-heading fw-bold mb-3">Still no luck? We can help!</h3>
                <p className="fw-normal">Get in touch with our friendly support team.</p>
                <Link to={"/raise-ticket"} className="submit-btn">Submit a request</Link>
            </div>
        </>
    )
}
export default StillNoLuck;