import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Corrected import statement
import CommonLayout from './layout/CommonLayout';
import Home from './pages/views/Home';
import RaiseTicket from './pages/views/RaiseTicket';
import TicketList from './pages/views/TicketList';
import SingleArticle from './pages/views/SingleArticle';
import TicketDetail from './pages/views/TicketDetails';

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<CommonLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/raise-ticket" element={<RaiseTicket />} />
          <Route path="/ticket-list" element={<TicketList />} />
          <Route path="/single-article" element={<SingleArticle />} />
          <Route path="/ticket-detail" element={<TicketDetail />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
