import React, { useState } from "react";
import Navigation from "../components/Navigation";
import ChatSupport from "../components/ChatSupport";
import { Outlet } from "react-router-dom";
const CommonLayout = () => {

    const [openChat, SetOpenChat] = useState(false)

    return(
        <>
            <Navigation SetOpenChat={SetOpenChat} openChat={openChat} />
            <Outlet />
            <ChatSupport openChat={openChat} SetOpenChat={SetOpenChat} />
            
        </>
    )
}
export default CommonLayout;